import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// material
import {
  experimentalStyled as styled,
  makeStyles
} from '@material-ui/core/styles';
import { Box, Container, Link } from '@mui/material';
import IconExpandMore from '@material-ui/icons/ExpandMore';
import Sidebar from './Sidebar';

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  dropdown: {
    '&:hover': {
      '& .dropdown-content': {
        display: 'block'
      }
    }
  },
  active: {
    '& .menuContext:after': {
      opacity: 1
    }
  },
  menuRoot: {
    '& .MuiPaper-root': {
      padding: '1rem'
    }
  },
  menuLink: {
    textDecoration: 'none',
    color: '#fff',
    width: '100%'
  },
  dropdownLink: {
    textDecoration: 'none',
    color: 'rgba(102,102,102,0.85)',
    width: '100%',
    '&:hover': {
      color: '#CD4A42'
    }
  }
}));

const RootStyle = styled('div')(({ theme }) => ({
  width: '100%',
  backgroundColor: '#F88120',
  [theme.breakpoints.down('md')]: {
    position: 'fixed',
    top: '0px',
    zIndex: 222
  }
}));

const ContentStyle = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'flex-start'
}));

const LogoStyle = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '200px',
  marginRight: '20px',
  cursor: 'pointer'
}));

const SidebarStyle = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const MenuStyle = styled('div')(() => ({
  marginLeft: 'auto',
  position: 'relative',
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center'
}));

const MenuListStyle = styled('ul')(() => ({
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'flex-end'
}));

const MenuItemStyle = styled('li')(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  cursor: 'pointer',
  color: '#fff',
  alignItems: 'center',
  padding: '0 1rem'
}));

const MenuItemTextStyle = styled('div')(() => ({
  fontSize: '16px',
  fontFamily: 'Montserrat',
  color: '#fff',
  fontWeight: '600',
  textDecoration: 'none',
  display: 'inline-flex',
  flexWrap: 'wrap',
  padding: '10px 0',
  alignItems: 'center',
  '&:after': {
    content: '""',
    display: 'block',
    width: '100%',
    height: '3px',
    opacity: 0,
    marginTop: '5px',
    background: '#04b4fa',
    transition: 'all .3s'
  },
  '&:hover::after': {
    opacity: 1
  }
}));

const DropdownStyle = styled('ul')(({ theme }) => ({
  display: 'none',
  position: 'absolute',
  top: '100%',
  backgroundColor: '#ffffff',
  minWidth: '240px',
  padding: '.5rem 0',
  boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
  marginRight: '6rem',
  zIndex: '1',
  '&:before': {
    content: '""',
    position: 'absolute',
    borderColor: 'rgba(194, 225, 245, 0)',
    border: 'solid transparent',
    borderBottomColor: 'white',
    borderWidth: '11px',
    marginLeft: '-10px',
    top: '-21px',
    left: '74%',
    zIndex: 1
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    left: '50%',
    top: '-21px',
    width: 0,
    height: 0,
    border: 'solid transparent',
    borderWidth: '10px',
    borderBottomColor: 'none',
    zIndex: 0
  },
  [theme.breakpoints.down('md')]: {
    minWidth: '140px',
    marginRight: '0rem',
    '&:before': {
      content: '""',
      position: 'absolute',
      borderColor: 'rgba(194, 225, 245, 0)',
      border: 'solid transparent',
      borderBottomColor: 'white',
      borderWidth: '11px',
      marginLeft: '-10px',
      top: '-21px',
      left: '50%',
      zIndex: 1
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      left: '50%',
      top: '-21px',
      width: 0,
      height: 0,
      border: 'solid transparent',
      borderWidth: '10px',
      borderBottomColor: 'none',
      zIndex: 0
    }
  }
}));

const DropdownItemStyle = styled('li')(() => ({
  listStyle: 'none',
  transition: 'all .2s',
  color: 'rgba(102,102,102,0.85)',
  padding: '10px 20px',
  borderBottom: '1px solid #ececec',
  textDecoration: 'none',
  fontSize: '16px',
  fontFamily: 'Source Sans Pro',
  fontWeight: '400',
  display: 'block',
  margin: '0px 10px'
}));

export default function NavbarwithoutWallet() {
  const classes = useStyles();
  const history = useHistory();
  const [hover, setHover] = useState(false);
  return (
    <RootStyle>
      <Container maxWidth="xl">
        <ContentStyle id="back-to-top-anchor">
          <LogoStyle
            onClick={() => {
              window.open('https://incomeisland.org', '_blank');
            }}
          >
            <Box
              component="img"
              src="/static/home/logo.png"
              style={{
                width: '113px',
                padding: '12px 0'
              }}
            />
          </LogoStyle>
          <MenuStyle>
            <MenuListStyle sx={{ display: { xs: 'none', sm: 'flex' } }}>
              <MenuItemStyle
                className={hover ? '' : classes.active}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <MenuItemTextStyle className="menuContext">
                  <Link
                    className={classes.menuLink}
                    underline="none"
                    href="https://incomeisland.org"
                    target="_current"
                  >
                    Home
                  </Link>
                </MenuItemTextStyle>
              </MenuItemStyle>
              <MenuItemStyle
                className={`${hover ? '' : classes.active} ${classes.dropdown}`}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <MenuItemTextStyle className="menuContext">
                  KYC <IconExpandMore />
                </MenuItemTextStyle>
                <DropdownStyle className="dropdown-content">
                  <DropdownItemStyle>
                    <Link
                      className={classes.dropdownLink}
                      underline="none"
                      href="https://github.com/solidproof/kyc-certificates/raw/main/KYC_Certificate_IncomeIsland.png"
                      target="_new"
                    >
                      Search / Verify on solidproof.io
                    </Link>
                  </DropdownItemStyle>
                  <DropdownItemStyle>
                    <Link
                      className={classes.dropdownLink}
                      underline="none"
                      href="https://twitter.com/SolidProof_io/status/1466843558976139269"
                      target="_new"
                    >
                      Official Twitter
                    </Link>
                  </DropdownItemStyle>
                  <DropdownItemStyle>
                    <Link
                      className={classes.dropdownLink}
                      underline="none"
                      href="https://github.com/solidproof/kyc-certificates/raw/main/KYC_Certificate_IncomeIsland.png"
                      target="_new"
                    >
                      KYC Certificate
                    </Link>
                  </DropdownItemStyle>
                </DropdownStyle>
              </MenuItemStyle>
              <MenuItemStyle
                className={`${hover ? '' : classes.active} ${classes.dropdown}`}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <MenuItemTextStyle className="menuContext">
                  Island Shop <IconExpandMore />
                </MenuItemTextStyle>
                <DropdownStyle className="dropdown-content">
                  <DropdownItemStyle>
                    <Link
                      className={classes.dropdownLink}
                      underline="none"
                      href="https://incomeisland.org/income-coin/"
                      target="_new"
                    >
                      Income Multi Chain Coin
                    </Link>
                  </DropdownItemStyle>
                  <DropdownItemStyle>
                    <Link
                      className={classes.dropdownLink}
                      underline="none"
                      href="https://incomeisland.org/buy-income-island/"
                      target="_new"
                    >
                      OG INCOME token
                    </Link>
                  </DropdownItemStyle>
                  <DropdownItemStyle>
                    <Link
                      className={classes.dropdownLink}
                      underline="none"
                      href="https://incomeisland.app/buy/"
                      target="_new"
                    >
                      Buy VRE Virtual Real Estate
                    </Link>
                  </DropdownItemStyle>
                  <DropdownItemStyle>
                    <Link
                      className={classes.dropdownLink}
                      underline="none"
                      href="https://incomeisland.org/NFT/"
                      target="_new"
                    >
                      Income Island Iguana Club NFT Collection
                    </Link>
                  </DropdownItemStyle>
                </DropdownStyle>
              </MenuItemStyle>
              <MenuItemStyle
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <MenuItemTextStyle>
                  <Link
                    className={classes.menuLink}
                    underline="none"
                    href="https://incomeislandllc.gitbook.io/income-island-whitepaper/"
                    target="_new"
                  >
                    Whitepaper
                  </Link>
                </MenuItemTextStyle>
              </MenuItemStyle>
              <MenuItemStyle
                className={`${hover ? '' : classes.active} ${classes.dropdown}`}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <MenuItemTextStyle className="menuContext">
                  Income Coin <IconExpandMore />
                </MenuItemTextStyle>
                <DropdownStyle className="dropdown-content">
                  <DropdownItemStyle>
                    <Link
                      className={classes.dropdownLink}
                      underline="none"
                      href="https://poocoin.app/tokens/0x75ef7e9028798b4deaa10ac8348dfe70b770325c/"
                      target="_new"
                    >
                      Buy with BNB - LIVE
                    </Link>
                  </DropdownItemStyle>
                  <DropdownItemStyle>
                    <Link
                      className={classes.dropdownLink}
                      underline="none"
                      href="https://app.groveswap.io/swap?chainId=770077&outputCurrency=0x75Ef7e9028798B4deaa10Ac8348dFE70b770325c/"
                      target="_new"
                    >
                      Buy with GRV (Grove) - LIVE
                    </Link>
                  </DropdownItemStyle>
                  <DropdownItemStyle>
                    <Link
                      className={classes.dropdownLink}
                      underline="none"
                      href="https://www.dextools.io/app/en/ether/pair-explorer/0xc1c8b34b395659d7eb962f88bbf0e40209ad83b0/"
                      target="_new"
                    >
                      Buy with ETH - LIVE
                    </Link>
                  </DropdownItemStyle>
                </DropdownStyle>
              </MenuItemStyle>
              <MenuItemStyle
                className={`${hover ? '' : classes.active} ${classes.dropdown}`}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <MenuItemTextStyle className="menuContext">
                  Downloads <IconExpandMore />
                </MenuItemTextStyle>
                <DropdownStyle className="dropdown-content">
                  <DropdownItemStyle>
                    <Link
                      className={classes.dropdownLink}
                      underline="none"
                      href="https://incomeisland.app/Income-Island-Metaverse-Demo.exe"
                      target="_new"
                    >
                      Download for PC
                    </Link>
                  </DropdownItemStyle>
                  <DropdownItemStyle>
                    <Link
                      className={classes.dropdownLink}
                      underline="none"
                      href="https://incomeisland.app/Income-Island-Metaverse-Demo.dmg"
                      target="_new"
                    >
                      Download for MacOS
                    </Link>
                  </DropdownItemStyle>
                  <DropdownItemStyle>
                    <Link
                      className={classes.dropdownLink}
                      underline="none"
                      href="https://apps.microsoft.com/store/detail/income-island-metaverse-beta/9NCVR44X7Q5C/"
                      target="_new"
                    >
                      Download from Microsoft Store
                    </Link>
                  </DropdownItemStyle>
                </DropdownStyle>
              </MenuItemStyle>
              <MenuItemStyle
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <MenuItemTextStyle
                  variant="contained"
                  onClick={() => {
                    history.push('/dashboard');
                  }}
                >
                  Dashboard
                </MenuItemTextStyle>
              </MenuItemStyle>
              <MenuItemStyle
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <MenuItemTextStyle>
                  <Link
                    className={classes.menuLink}
                    underline="none"
                    href="https://incomeisland.org/llc/"
                    target="_new"
                  >
                    LLC
                  </Link>
                </MenuItemTextStyle>
              </MenuItemStyle>
              <MenuItemStyle
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <MenuItemTextStyle
                  variant="contained"
                  onClick={() => {
                    history.push('/bridge');
                  }}
                >
                  Bridge
                </MenuItemTextStyle>
              </MenuItemStyle>
            </MenuListStyle>
          </MenuStyle>
          <SidebarStyle>
            <Sidebar />
          </SidebarStyle>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
