import React from 'react';

import { motion } from 'framer-motion';
// material
import {
  experimentalStyled as styled,
  makeStyles
} from '@material-ui/core/styles';
import {
  Box,
  Container,
  Typography,
  Link,
  Divider,
  Fab
} from '@material-ui/core';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ScrollTop from './ScrollTop';
// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  caption: {
    color: '#fff',
    fontSize: '21px',
    fontFamily: 'Source Sans Pro',
    padding: '0 .5rem',
    fontWeight: '400',
    '&:hover': {
      color: '#F88120'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '16px'
    }
  },
  policy: {
    color: '#fff',
    fontSize: '21px',
    fontFamily: 'Source Sans Pro',
    padding: '0 .5rem',
    fontWeight: '400',
    '&:hover': {
      color: '#F88120'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '16px'
    }
  },
  copy: {
    color: '#fff',
    fontSize: '21px',
    fontFamily: 'Source Sans Pro',
    fontWeight: '400',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
      display: 'block'
    }
  },
  company: {
    color: '#F88120',
    fontSize: '21px',
    fontFamily: 'Source Sans Pro',
    fontWeight: '400',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px'
    }
  }
}));
const RootStyle = styled(motion.div)(() => ({
  width: '100%',
  backgroundColor: '#000',
  borderTop: '5px solid #F88120'
}));

const FooterTopStyle = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: '73.5px',
  [theme.breakpoints.down('md')]: {
    padding: '1rem 0'
  }
}));
const FooterListStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column'
  }
}));

const FooterBottomStyle = styled('div')(() => ({
  width: '100%',
  textAlign: 'center'
}));

const FabStyle = styled(Fab)(() => ({
  background: 'transparent',
  border: '1px solid #E4E4E4',
  color: '#E4E4E4',
  boxShadow: 'none',
  '&:hover': {
    background: '#E4E4E4',
    color: '#000'
  }
}));
export default function Footer(props) {
  const classes = useStyles();
  return (
    <RootStyle>
      <Container maxWidth="xl">
        <FooterTopStyle>
          <Box
            sx={{ display: { xs: 'none', md: 'flex' }, padding: '56px 0 0 0' }}
          >
            <Box
              component="img"
              src="/static/home/footer-logo.png"
              alt="logo"
              style={{ width: '250px', marginBottom: '20px' }}
            />
          </Box>
          <Box
            sx={{ display: { xs: 'flex', md: 'none' }, paddingBottom: '.5rem' }}
          >
            <Box
              component="img"
              src="/static/home/footer-logo.png"
              alt="logo"
              style={{ width: '113px', marginBottom: '5px' }}
            />
          </Box>

          <Link
            underline="none"
            href="https://secure.trust-provider.com"
            target="_blank"
            sx={{
              display: { xs: 'none', md: 'flex' },
              paddingBottom: '36px',
              paddingTop: '10px'
            }}
          >
            <Box component="img" src="/static/home/sslM.png" alt="logo" />
          </Link>

          <Link
            underline="none"
            href="https://secure.trust-provider.com"
            target="_blank"
            sx={{ display: { xs: 'flex', md: 'none' }, paddingBottom: '.5rem' }}
          >
            <Box component="img" src="/static/home/ssls.png" alt="logo" />
          </Link>

          <FooterListStyle>
            <Link
              underline="none"
              href="https://incomeislandllc.gitbook.io/income-island-whitepaper/"
              target="_blank"
            >
              <Typography variant="caption" className={classes.caption}>
                Whitepaper
              </Typography>
            </Link>
            <Link
              underline="none"
              href="https://incomeisland.org/terms-and-conditions/"
              target="_blank"
            >
              <Typography variant="caption" className={classes.policy}>
                Terms and Conditions
              </Typography>
            </Link>
            <Link
              underline="none"
              href="https://www.coinscope.co/coin/income/audit"
              target="_blank"
            >
              <Typography variant="caption" className={classes.caption}>
                Audit
              </Typography>
            </Link>
            <Link
              underline="none"
              href="https://apps.microsoft.com/store/detail/income-island-metaverse-beta/9NCVR44X7Q5C"
              target="_blank"
            >
              <Typography variant="caption" className={classes.caption}>
                Metaverse
              </Typography>
            </Link>
          </FooterListStyle>
        </FooterTopStyle>
        <Divider />
        <FooterBottomStyle>
          <Box
            component="div"
            sx={{
              padding: { md: '25.5px 0 58px 0', xs: '1rem 0' }
            }}
          >
            <Typography variant="caption" className={classes.copy}>
              &copy; 2023&nbsp;
              <Typography variant="caption" className={classes.company}>
                Income Island LLC.&nbsp;&nbsp;
              </Typography>
            </Typography>
            <Typography variant="caption" className={classes.copy}>
              All Rights Reserved
            </Typography>
          </Box>
          <Box
            component="div"
            sx={{
              padding: { md: '0px 0 18px 0', xs: '1rem 0' },
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Box
              component="img"
              src="/static/home/Telegram.png"
              sx={{
                width: { md: '49px', xs: '30px' },
                height: { md: '49px', xs: '30px' },
                cursor: 'pointer',
                margin: { md: '0px 3.1px 0px 3.1px', xs: '0px 1px 0px 1px' }
              }}
              onClick={() => {
                window.open('https://t.me/incomeisland');
              }}
              alt="logo"
            />
            <Box
              component="img"
              src="/static/home/Twitter.png"
              sx={{
                width: { md: '49px', xs: '30px' },
                height: { md: '49px', xs: '30px' },
                cursor: 'pointer',
                margin: { md: '0px 3.1px 0px 3.1px', xs: '0px 1px 0px 1px' }
              }}
              onClick={() => {
                window.open('https://twitter.com/incomeisland?s=20');
              }}
              alt="logo"
            />
            <Box
              component="img"
              src="/static/home/Linkedin.png"
              sx={{
                width: { md: '49px', xs: '30px' },
                height: { md: '49px', xs: '30px' },
                cursor: 'pointer',
                margin: { md: '0px 3.1px 0px 3.1px', xs: '0px 1px 0px 1px' }
              }}
              onClick={() => {
                window.open(
                  'https://www.linkedin.com/company/income-island-llc'
                );
              }}
              alt="logo"
            />
            <Box
              component="img"
              src="/static/home/Instagram.png"
              sx={{
                width: { md: '49px', xs: '30px' },
                height: { md: '49px', xs: '30px' },
                cursor: 'pointer',
                margin: { md: '0px 3.1px 0px 3.1px', xs: '0px 1px 0px 1px' }
              }}
              onClick={() => {
                window.open(
                  'https://instagram.com/incomeislandmetaverse?utm_medium=copy_link'
                );
              }}
              alt="logo"
            />
            <Box
              component="img"
              src="/static/home/Facebook.png"
              sx={{
                width: { md: '49px', xs: '30px' },
                height: { md: '49px', xs: '30px' },
                cursor: 'pointer',
                margin: { md: '0px 3.1px 0px 3.1px', xs: '0px 1px 0px 1px' }
              }}
              onClick={() => {
                window.open('https://www.facebook.com/incomeislandmetaverse');
              }}
              alt="logo"
            />
            <Box
              component="img"
              src="/static/home/Youtube.png"
              sx={{
                width: { md: '49px', xs: '30px' },
                height: { md: '49px', xs: '30px' },
                cursor: 'pointer',
                margin: { md: '0px 3.1px 0px 3.1px', xs: '0px 1px 0px 1px' }
              }}
              onClick={() => {
                window.open(
                  'https://youtube.com/channel/UCPSYtZiO7gqj_eWmHDvjRvg'
                );
              }}
              alt="logo"
            />
            <Box
              component="img"
              src="/static/home/Soundcloud.png"
              sx={{
                width: { md: '49px', xs: '30px' },
                height: { md: '49px', xs: '30px' },
                cursor: 'pointer',
                margin: { md: '0px 3.1px 0px 3.1px', xs: '0px 1px 0px 1px' }
              }}
              onClick={() => {
                window.open('https://soundcloud.com/incomeisland');
              }}
              alt="logo"
            />
            <Box
              component="img"
              src="/static/home/Twitch.png"
              sx={{
                width: { md: '49px', xs: '30px' },
                height: { md: '49px', xs: '30px' },
                cursor: 'pointer',
                margin: { md: '0px 3.1px 0px 3.1px', xs: '0px 1px 0px 1px' }
              }}
              onClick={() => {
                window.open('https://www.twitch.tv/incomeisland');
              }}
              alt="logo"
            />
          </Box>
        </FooterBottomStyle>
      </Container>
      <ScrollTop {...props}>
        <FabStyle size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </FabStyle>
      </ScrollTop>
    </RootStyle>
  );
}
