import faker from 'faker';
// utils
import mock from '../utils/mock';
import { codes } from '../utils/helpError';
import fakeRequest from '../utils/fakeRequest';

// ----------------------------------------------------------------------

const properties = [
  {
    id: '8864c717-587d-472a-929a-8e5f298024da-0',
    title: 'Apartment',
    price: 100,
    mapSize: 200,
    totalPlots: 560,
    description: 'This is description'
  }
];

// ----------------------------------------------------------------------

mock.onPost('/api/property/insert').reply(async (config) => {
  try {
    await fakeRequest(1000);

    const { title, price, mapSize, totalPlots, description } = JSON.parse(
      config.data
    );
    let property = properties.find((_user) => _user.title === title);

    if (property) {
      return [400, { message: codes.emailAlreadyinUse.code }];
    }

    property = {
      id: faker.datatype.uuid(),
      title,
      price,
      mapSize,
      totalPlots,
      description
    };

    return [200, { property }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});
