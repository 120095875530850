import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// material
import {
  experimentalStyled as styled,
  makeStyles
} from '@material-ui/core/styles';
import { Paper, IconButton, Modal, Typography, Grid } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import closeFill from '@iconify/icons-eva/close-fill';
import { useWallet } from 'use-wallet';
import { useSnackbar } from 'notistack';
import { MIconButton } from 'src/components/@material-extend';
import { Icon } from '@iconify/react';
import { useDispatch, useSelector } from 'react-redux';
import Web3 from 'web3';
import useAuth from '../../hooks/useAuth';
import { getChainID } from '../../redux/slices/user';
// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  dropdownLink: {
    textDecoration: 'none',
    color: 'rgba(102,102,102,0.85)',
    '&:hover': {
      color: '#CD4A42'
    }
  }
}));

const RootStyle = styled('div')(() => ({
  width: '100%'
}));

const PanelStyle = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '1200px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '32px',
  width: '350px',
  [theme.breakpoints.down(1200)]: {
    width: '350px'
  },
  [theme.breakpoints.down(400)]: {
    width: '250px'
  }
}));

const PanelHeaderStyle = styled('div')(() => ({
  textAlign: 'left',
  borderBottom: '1px solid #e5e2e2',
  padding: '1rem 0.6rem 0.6rem 1rem'
}));

const PanelContentStyle = styled('div')(({ theme }) => ({
  padding: '0rem',
  textAlign: 'center',
  overflowY: 'auto',
  flex: '1 1 auto',
  height: '280px',
  minHeight: '220px',
  [theme.breakpoints.down(400)]: {
    width: '247px'
  }
}));

const CloseButtonStyle = styled(IconButton)(({ theme }) => ({
  borderColor: '#050404',
  color: 'rgb(11, 133, 186)',
  '&:hover': {
    backgroundColor: '#c9c4c4'
  },
  [theme.breakpoints.down(400)]: {
    padding: '8px'
  }
}));

const ButtonGroupStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  minHeight: '220px',
  [theme.breakpoints.down(400)]: {
    width: '247px'
  }
}));

const ButtonStyle = styled(IconButton)(({ theme }) => ({
  WebkitBoxAlign: 'center',
  alignItems: 'center',
  backgroundColor: 'rgb(239, 244, 245)',
  border: '0px',
  borderRadius: '16px',
  boxShadow: 'none',
  color: 'rgb(11, 133, 186)',
  cursor: 'pointer',
  display: 'inline-flex',
  fontSize: '16px',
  fontWeight: 600,
  width: '100%',
  height: '48px',
  lineHeight: 1,
  letterSpacing: '0.03em',
  WebkitBoxPack: 'center',
  justifyContent: 'center',
  outline: '0px',
  padding: '0px 27px',
  transition: 'background-color 0.2s ease 0s',
  opacity: 1,
  marginBottom: '8px',
  justifyContent: 'space-between',
  '&:hover': {
    cursor: 'pointer'
  }
}));

const MetaMaskIconStyle = styled('img')(({ theme }) => ({
  width: '23px',
  height: '23px',
  [theme.breakpoints.down(400)]: {
    width: '17px',
    height: '17px'
  }
}));

const TrustIconStyle = styled('img')(({ theme }) => ({
  width: '23px',
  height: '23px',
  [theme.breakpoints.down(400)]: {
    width: '17px',
    height: '17px'
  }
}));

const SafePalIconStyle = styled('img')(({ theme }) => ({
  width: '23px',
  height: '23px',
  [theme.breakpoints.down(400)]: {
    width: '17px',
    height: '17px'
  }
}));

const TextStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.down(400)]: {
    fontSize: '14px'
  }
}));

ConnectModal.propTypes = {
  open: PropTypes.any,
  handleOpen: PropTypes.any,
  handleClose: PropTypes.any,
  setAccount: PropTypes.any,
  account: PropTypes.any
};

export default function ConnectModal({
  open,
  handleClose,
  setAccount,
  account
}) {
  const dispatch = useDispatch();
  const { chainID } = useSelector((state) => state.user);

  const wallet = useWallet();
  const { user } = useAuth();

  const [login, setLogin] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const connectWallet = (connector) => {
    wallet
      .connect(connector)
      .then(() => {})
      .catch((e) => {
        console.log('e', e);
      });
  };

  useEffect(async () => {
    if (wallet.status === 'connected') {
      setLogin(true);
      console.log('window.location.href', window.location.href);

      if (
        user != null &&
        wallet.account !== user.walletAddress &&
        window.location.href !== 'https://incomeisland.app/bridge'
      ) {
        enqueueSnackbar(
          'Please use your wallet registered in the profile. (' +
            user.walletAddress +
            ')',
          {
            variant: 'error',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          }
        );
        setLogin(false);
        wallet.reset();
        setAccount(null);
      } else {
        setAccount(wallet.account);
        window.web3 = new Web3(wallet.ethereum);
        window.web3.eth.defaultAccount = wallet.account;
        localStorage.setItem('connectedWallet', wallet.account);
        setLogin(true);
        handleClose();
      }
    } else if (wallet.status === 'disconnected') {
      if (login) {
        localStorage.removeItem('connectedWallet');
        setLogin(false);
        wallet.reset();
        setAccount(null);
      } else if (
        localStorage.getItem('connectedWallet') !== '' &&
        localStorage.getItem('connectedWallet') !== null
      ) {
        wallet.connect();
      }
    } else if (wallet.status === 'error') {
      switchNetwork(chainID);
    }
  }, [wallet.status, open]);

  useEffect(() => {
    if (account) handleClose();
  }, [account]);

  useEffect(() => {
    switchNetwork(chainID);
  }, [chainID]);

  const switchNetwork = async (chainID) => {
    const rpcs = {
      56: 'https://bsc-dataseed.binance.org',
      97: 'https://bsc-testnet.publicnode.com',
      5: 'https://goerli.infura.io/v3/04717abe3f0f4e7686814322c2f391cd',
      1: 'https://mainnet.infura.io/v3/04717abe3f0f4e7686814322c2f391cd',
      770077: 'https://mainnet.grovechain.io',
      137: 'https://polygon-rpc.com'
    };

    const symbols = {
      56: 'BNB',
      97: 'BNB',
      5: 'ETH',
      1: 'ETH',
      137: 'MATIC',
      770077: 'Grv'
    };

    const network = {
      56: 'Binance Smart Chain',
      97: 'Binance Smart Chain Testnet',
      5: 'Test Ethereum Network',
      1: 'Ethereum Network',
      137: 'Polygon network',
      770077: 'Grove'
    };

    const blockExplorerUrls = {
      56: 'https://bscscan.com/',
      97: 'https://testnet.bscscan.com/',
      5: 'https://goerli.etherscan.io/',
      1: 'https://etherscan.io/',
      770077: 'https://grvscan.io/',
      137: 'https://polygonscan.com/'
    };

    if (window.ethereum) {
      try {
        // Try to switch to the Mumbai testnet
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x' + chainID.toString(16) }] // Check networks.js for hexadecimal network ids
        });
        // wallet.connect();
      } catch (error) {
        // This error code means that the chain we want has not been added to MetaMask
        // In this case we ask the user to add it to their MetaMask
        if (error.code === 4902) {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: '0x' + chainID.toString(16),
                  chainName: network[chainID],
                  rpcUrls: [rpcs[chainID]],
                  nativeCurrency: {
                    name: symbols[chainID],
                    symbol: symbols[chainID],
                    decimals: 18
                  },
                  blockExplorerUrls: [blockExplorerUrls[chainID]]
                }
              ]
            });
            // wallet.connect();
          } catch (error) {
            console.log(error);

            enqueueSnackbar(`Please select the ${network[chainID]} network.`, {
              variant: 'error',
              action: (key) => (
                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                  <Icon icon={closeFill} />
                </MIconButton>
              )
            });

            wallet.reset();
            setAccount(null);
          }
        } else if (error.code == 4001) {
          dispatch(chainID);
        }
        console.log(error);
      }
    } else {
      // If window.ethereum is not found then MetaMask is not installed
      console.log(
        'MetaMask is not installed. Please install it to use this app: https://metamask.io/download.html'
      );
    }
  };

  return (
    <RootStyle>
      <Modal
        open={open}
        onClose={() => {
          handleClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <PanelStyle>
          <PanelHeaderStyle>
            <Grid container spacing={2}>
              <Grid item xs sx={{ display: 'flex', alignItem: 'center' }}>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: {
                      md: '21px'
                    },
                    fontFamily: 'Source Sans Pro',
                    fontWeight: '800',
                    color: 'rgb(11, 133, 186)',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  Connect to a wallet
                </Typography>
              </Grid>
              <Grid item xs="auto">
                <CloseButtonStyle
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <CloseIcon />
                </CloseButtonStyle>
              </Grid>
            </Grid>
          </PanelHeaderStyle>
          <PanelContentStyle>
            <ButtonGroupStyle>
              <ButtonStyle onClick={() => connectWallet('injected')}>
                <TextStyle>Metamask</TextStyle>
                <MetaMaskIconStyle src="/static/home/metamask.png" />
              </ButtonStyle>
              <ButtonStyle onClick={() => connectWallet('walletconnect')}>
                <TextStyle>Wallet Connect</TextStyle>
                <TrustIconStyle src="/static/home/walletConnectIcon.svg" />
              </ButtonStyle>
              <ButtonStyle onClick={() => wallet.connect()}>
                <TextStyle>Trust Wallet</TextStyle>
                <TrustIconStyle src="/static/home/TWT.png" />
              </ButtonStyle>
              <ButtonStyle onClick={() => wallet.connect()}>
                <TextStyle>SafePal</TextStyle>
                <SafePalIconStyle src="/static/home/sfp.png" />
              </ButtonStyle>
            </ButtonGroupStyle>
          </PanelContentStyle>
        </PanelStyle>
      </Modal>
    </RootStyle>
  );
}
