import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  properties: [],
  property: null
};

const slice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROPERTIES
    getPropertiesSuccess(state, action) {
      state.isLoading = false;
      state.properties = action.payload;
    },

    // GET PROPERTY
    getPropertySuccess(state, action) {
      state.isLoading = false;
      state.property = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getProperties() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/property/getAll`,
        {},
        {
          headers: { Authorization: accessToken }
        }
      );
      dispatch(slice.actions.getPropertiesSuccess(response.data.properties));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProperty(propertyid) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/property/byId`,
        {
          id: propertyid
        },
        {
          headers: { Authorization: accessToken }
        }
      );

      dispatch(slice.actions.getPropertySuccess(response.data.property));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
