import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  themeMode: 'light',
  themeDirection: 'ltr',
  error: false,
  miningStatus: false
};

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    switchMode(state, action) {
      state.themeMode = action.payload;
    },
    switchDirection(state, action) {
      state.themeDirection = action.payload;
    },
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET PROPERTY
    getMiningStatusSuccess(state, action) {
      state.isLoading = false;
      state.miningStatus = action.payload === 'true';
    }
  }
});

// Reducer
export default slice.reducer;

export function getMiningStatus() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/setting/get/miningstatus`,
        {
          headers: { Authorization: accessToken }
        }
      );
      dispatch(
        slice.actions.getMiningStatusSuccess(response.data.result[0].value)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Actions
export const { switchMode, switchDirection } = slice.actions;
