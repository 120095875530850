// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_BUY = '/buy';
const ROOTS_IGUANA = '/iguana';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify/:email'),
  forgotPassword: path(ROOTS_AUTH, '/forgot-password/:email')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  page404: '/404',
  page500: '/500'
};

export const PATH_HOME = {
  root: '/',
  profile: '/profile',
  dashboard: ROOTS_DASHBOARD,
  buy: ROOTS_BUY
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  admins: path(ROOTS_DASHBOARD, '/admins')
};

export const PATH_BUY = {
  root: ROOTS_BUY,
  lands: path(ROOTS_BUY, '/lands'),
  detail: path(ROOTS_BUY, '/detail/:id')
};

export const PATH_IGUANA = {
  root: ROOTS_IGUANA,
  detail: path(ROOTS_IGUANA, '/result/:id')
};
